import React, { useState, useEffect, useContext, Fragment } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Layout from "layout"
import Message from "elements/Message"
import Container from "layout/Container"
import Section from "elements/Section"
import ActionButtons from "elements/ActionButtons"

import { getSignedInUser, hasRoles } from "../Auth/services/user"
import { AppContext } from "context/AppContext"
import { isBrowser, isObjectEmpty } from "services/general"

import { useMentalHealthFormFields } from "./hooks/useAirtableMHFormFields"
import { generateFormField } from "elements/Form/services/form"
import { generateInitialValues } from "../../services/context"

import { GATSBY_MENTAL_HEALTH_PROGRAM_CODE } from "gatsby-env-variables"

const Enrollment = (props) => {
  const [loading, setLoading] = useState(false)

  const { state, dispatch } = useContext(AppContext)
  const { domain } = getSignedInUser().userData || {}

  let exclude = ["-", "email"]

  if (domain?.validity) {
    exclude = ["employeeId", ...exclude]
  }

  if (!domain?.eligiblePrograms?.includes(GATSBY_MENTAL_HEALTH_PROGRAM_CODE)) {
    exclude = ["maxicareCardNumber", ...exclude]
  }

  let { pageContext } = props

  const handleSubmit = async (values) => {
    setLoading(true)

    await dispatch({
      type: "SAVE_ENROLLMENT",
      payload: {
        ...values,
      },
    })

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        enrollment: { ...state.enrollment, ...values },
        // auth: { ...values.auth },
      },
    })
    navigate("/enrollment/summary")
  }

  useEffect(() => {
    if (isBrowser()) {
      dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
      if (hasRoles()) navigate("/profile")
    }
  }, [dispatch])

  let formFields = pageContext.formFields.filter(
    (field) => !exclude.includes(field.name)
  )

  let siteOptions = domain?.siteOptions
    ? domain?.siteOptions
    : state.enrollment.siteOptions

  formFields.forEach((field, index) => {
    if (field.name === "site") {
      if (siteOptions && siteOptions?.length > 0) {
        field.options = siteOptions
      } else formFields.splice(index, 1)
    }
  })

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields,
  })

  let initialValues = {
    ...generateInitialValues({ fields: formFields }),
    ...state?.enrollment,
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      display={{ footer: false, helpCenterBanner: false }}
      isPrivate
      polygonStyle="detailsForms"
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {sectionFormFields
                .sort(
                  (firstFormField, secondFormField) =>
                    firstFormField.order - secondFormField.order
                )
                .map((section) => (
                  <Section
                    title={section?.section}
                    subtitle={section?.subtitle || ""}
                    id={section?.sectionId || ""}
                  >
                    {section?.message && (
                      <Message color="light">{section?.message}</Message>
                    )}
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            {generateFormField({
                              formFields: section?.fields,
                              formField: field,
                              values,
                              setFieldValue,
                              context: {
                                state,
                                dispatch,
                              },
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </Section>
                ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{ label: "Continue", loading }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Enrollment
